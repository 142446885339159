body{
    overflow-x:hidden;
    width:100vw;
}

#section_masthead{
    min-height:calc(40vh - var(--margin)*2);
    display: flex;
    align-items:flex-end;
    background: black;
    // background: var(--yellow);
    background: white;
    color: black;
    
    canvas{
        // z-index:0;
        // transform:scale(1.2) translateX();
    }
    // color: white;
    .inner{
        // margin: 0 auto;
        z-index:1;
        // position:fixed;
        // z-index:0;
    }
    h1{
        line-height: 0.95;
        margin-top: 0;
        font-size: 70px;
        max-width: 1400px;
        margin-bottom:0;
        // text-align:left;
    }
    p{
        font-size: 1.75rem;
        // color:white;   
        font-family: var(--serif);
        font-weight: 300; 
        max-width: 100%;
    }
    svg{
        width:100%;
        height: auto;
        position:absolute;
        bottom:0px;
        left:0px;
        opacity: 0.95;
        pointer-events: none;
    }
    video {
        max-width: 100%;
    }
}

@media screen and (max-width: 1177px){
    #section_masthead{
        h1{
            font-size: 62px;
            letter-spacing: -0.05em;
        }
    }
}

@media screen and (max-width: 920px){
    #section_masthead{
        h1{
            font-size: 45px;
            letter-spacing: -0.05em;
        }
    }
}

@media screen and (max-width: 490px){
    #section_masthead{
        h1{
            font-size: 36px;
        }
    }
}   

#section_interactive{
    padding: 0;
    background: var(--yellow);
    mix-blend-mode: multiply;
    max-width:100%;
    canvas{
        mix-blend-mode: multiply;
        opacity: 0.8;
        height:150vh;
        // background: var(--yellow);
        // filter:contrast(1.75);
    }
    #label{
        position:fixed;
        background: white;
        border: 1px solid black;
        font-family: Garaje 0504 VAR Trial, 'Garaje 0504 VAR Trial', 'Courier', monospace !important;
        font-size: 12px;
        padding: 4px 8px;
        pointer-events:none;
        text-transform: uppercase;
        letter-spacing: 0em;
        display:none;
        visibility:hidden;
    }
}
#section_interactive:hover #label{
    display:block;
}
// #section_interactive:after{
//     width:100%;
//     height: 100%;
//     content: '';
//     background: rgb(255, 213, 0);
//     position: absolute;
//     top:0;
//     left:0;
//     mix-blend-mode: hard-light;
// }
// #section_interactive:after{
//     content:'';
//     position:absolute;
//     top:0px;
//     left:0px;
//     width: 100vw;
//     height: 100vh;
//     background: #F0C108;
//     mix-blend-mode: lighten;
// }

#section_mission{
    background: var(--yellow);
    padding-top: 0;
    .inner{
        width: 100%;
        padding-top: 1px;
        // padding-bottom: 50px;
        padding-bottom:0px;
        display: flex;
        gap:var(--margin);
        h2{
            margin-top:0;
        }
        p{
            // font-size: 1.5rem;
            max-width: 750px;
            margin-top:0.20em;
            line-height:1.3;
        }
        > *{
            flex: 1;
            width: 50%;
        }

    }
    svg{
        width:100vw;
        height: auto;
        position:absolute;
        top:0;
        left:0;
        opacity: 0.95;
        display:none;
        path{
            fill: var(--yellow);
        }
    }
    h2{
        font-family: var(--serif);
        letter-spacing: -0.02em;
    }
}
@media screen and (max-width: 1050px){
    #section_interactive{
        // height: 100vh;
    }
    #section_mission{
        .inner{
            // width: 75%;
            display: block;
            > *{
                width: 75%;
            }
        }     
    } 
}
@media screen and (max-width: 700px){
    #section_mission .inner > *{
        width: 100%;  
    } 
    #section_mission h2{
        font-size: 2rem;
    }
}
#section_video{
    padding:0;
    video{
        width: 100vw;
        // margin-left:calc(-1 * var(--margin));
        height: calc((100vw)*1080/1920);
    }
}
#section_interview{
    // display: flex;
    // justify-content: flex-end;
    padding-top:calc(var(--margin)*1.5);
    padding-bottom:calc(var(--margin)*1.5);
    .inner{
    }
    .cols{
        display:flex;
        gap: var(--gap);
        > *:first-child{
            flex: 1;
            .inner{
                width: 75%;
            }
        }
        > *:last-child{
            flex: 2;
        }
        p{
            margin-top:0px;
        }
    }
    .img-container{
        width: 100%; /* Adjust based on the layout */
        height: 0;
        padding-bottom: 56.25%; /* 1080/1920 = 0.5625 or 56.25% */
        position: relative;
        // background: red;
        border-radius: 10px;
        overflow: hidden;
        // cursor:pointer;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; /* or 'contain' depending on your need */
        position: absolute;
        top: 0;
        left: 0;
    }
    .play-btn{
        position:absolute;
        left:50%;
        top:50%;
        transform:translate(-50%,-50%);
        opacity: 0.8;
        width: 50px;
    }
    .img-container:hover{
        // svg{
        //     opacity: 0.4;
        // }
        svg path{
            fill: var(--yellow);
        }
    }
    .pullquote{
        font-weight:bold;
        font-size: 1.5rem;
        position: relative;
        line-height: 1.15;
    }
    // .pullquote:before{
    //     content: '“';
    //     position:absolute;
    //     left:0px;
    //     top:0px;
    //     transform:translateX(-100%);

    // }
}
@media screen and (max-width: 850px){
    #section_interview{
        .cols{
            flex-direction:column-reverse;
        }
    }
}
#section_logos, #section_backed{
    padding-top:calc(var(--margin)*1.5);
    padding-bottom:calc(var(--margin)*1.5);
    h3{
        margin-top:0;
        font-size: 2rem;
        width:50%;
    }
    .logos{
        display: flex;
        gap: calc(var(--gap)*2);
        flex-wrap: wrap;
        margin-top: 50px;
        img{
            // filter: invert(1);
            width: 120px;
            height: 60px;
            object-fit: contain;;
        }
    }
    .cols{
        display:flex;
        gap: var(--gap);
        > *:first-child{
            width: calc(var(--col-3-width)*2);
            flex: 4;
        }
        > *:last-child{
            width: calc(var(--col-3-width));
            flex: 3;
        }
    }
    .img-container{
        width: 100%; /* Adjust based on the layout */
        height: 0;
        padding-bottom: 56.25%; /* 1080/1920 = 0.5625 or 56.25% */
        position: relative;
        // background: red;
        border-radius: 10px;
        overflow: hidden;
        // cursor:pointer;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; /* or 'contain' depending on your need */
            position: absolute;
            top: 0;
            left: 0;
        }
        .play-btn{
            position:absolute;
            left:50%;
            top:50%;
            transform:translate(-50%,-50%);
            opacity: 0.8;
            width: 50px;
        }
    }
    .img-container:hover{
        // svg{
        //     opacity: 0.4;
        // }
        background: var(--yellow);
        svg path{
            fill: var(--yellow);
        }
    }
}
@media screen and (max-width: 1050px){
    #section_logos, #section_backed{
        h3{
            width: 75%;
        }
    }
}
@media screen and (max-width: 850px){
    #section_logos{
        .logos{
            gap: var(--gap);
        }
        .cols{
            flex-direction: column-reverse;
        }
        div {
            min-width: 100%;
        }

    }
}
@media screen and (max-width: 700px){
    #section_logos, #section_backed{
        h3{
            width: 100%;
            font-size: 1.5rem;
        }
    }
}
#section_backed{
        .logos img{
            width: 200px;
        }
}
#section_features{
    margin-top: 100px;
    h2{
        max-width:50%;
        // h2{
            font-family: var(--serif);
            letter-spacing: -0.02em;
        // }
    }
    .cols{
        display:flex;
        gap: var(--gap);
        > *{
            width: var(--col-3-width);
        }
    }
    .col {
        display: flex;
        flex: 1;
        flex-direction: column;
        .container {
            flex-grow: 1;
        }
    }
    .image-container{
        background: white;
    }
    img{
        width: 100%;
        border: 1px solid var(--grey);
        // mix-blend-mode: exclusion;
        // position: relative;
        // display: block;
        // height: 100%;
        // object-fit: cover;
        // border:0;
    }
    h4{
        font-size:2rem;
        max-width: 250px;
    }
    p.small{
        font-size:16px;
        // max-width: 350px;
    }
    .container{
        margin-top: 1em;
    }
}

@media screen and (max-width: 1050px){
    #section_features h2{
        max-width: 75%;
    }
}

@media screen and (max-width: 850px){
    #section_features{
        .cols{
            flex-direction: column;
            > *{
                width: 100%;
                // display:flex;
                gap: var(--gap);
                flex-direction: row-reverse;
                > * {
                    flex: 1;
                    width: 50%;
                }
                .image-container{
                    order:1;
                }
                .container{
                    order: 2;
                }
            }
        }
    }
}
@media screen and (max-width: 700px){
    #section_features{
        h2{
            width: 100%;
            max-width: 100%;
            font-size: 2rem;
        }
        .cols{
            flex-direction: column;
            > *{
                width: 100%;
                // display:flex;
                gap: 10px;
                flex-direction: column;
                > * {
                    flex: 1;
                    width: 100%;
                }
                .image-container{
                    order:1;
                }
                .container{
                    order: 2;
                    margin-top: 0;;
                }
                h4{
                    font-size: 1.5rem;
                    max-width: 100%;
                }
            }
        }
    }
}


#section_team{
    padding-bottom: 100px;
    margin-top:200px;
    h3{
        font-weight: 700;
        display: inline;
        font-size: 1.25rem;
        line-height: 1.28;
        color: #1B1B1B;
        max-width: 600px;
        margin-bottom: 0;
    }
    .team{
        display:flex;
        gap: var(--gap);
    }
    .intro{
        margin-bottom: 2rem;
        display:flex;
        gap: var(--margin);
        h2{
            margin-top:0;
        }
        p, h3{
            // font-size: 1.5rem;
    max-width: 750px;
    margin-top: 0.2em;
    line-height: 1.3;
        }
        > * {
            flex: 1;
            width: 50%;
        }
    }
    h2{
        font-family: var(--serif);
        letter-spacing: -0.02em;
        max-width: 50%;
    }
    h4{
        // margin-top:1em;
    }
    .team-member{
        width: var(--col-3-width);
        transition: width 320ms;
        .video-info{
            margin-bottom:1em;
        }
        .accolades{
            display:none;
            gap: 20px;
            > *{
                width:100%;
                flex:1;
                border: 1px solid var(--grey);
                padding:10px;
                background:#fafaf5;
            }
            .label{
                font-weight:bold;
                display: block;;
                font-size: 15px;
                margin-top:0;
                margin-bottom:0.5em;
            }
            .label+p{
                margin-top:0;
            }
        }
        // cursor:pointer;
        .image-container{
            width:100%;
            // height: calc(var(--col-3-width)*1.3);
            height: calc(var(--col-3-width)*0.8);
            position:relative;
            z-index: -1 ;
            background: black;
            overflow:hidden;
            // border-radius: 10px;
            img{
                // width: 600px;
                height: 100%;
                object-fit:cover;
                position:absolute;
                left:50%;
                transform:translateX(-50%);
            }
        }
        .video-info{
            height: auto;
            max-height:0;
            overflow:hidden;
            transition: max-height 320ms;
        }
        p, h3{
            transition: font-size 320ms;
        }
        p {
            margin-top: 0;
        }
        svg{
            opacity:0;
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%, -50%);
            width: 50px;
            z-index:2;
            transition: opacity 320ms;
        }

    }
    .team-member{
        // cursor:pointer; 
    }
    .team-member:hover{
        border: 1px solid var(--grey);
        // padding:20px;
        .image-container{
            background:var(--yellow);
        }
    }
    .team-member:hover{
        width: calc(var(--col-3-width)*3);
        .accolades{
            display:flex;
        }
        .video-info{
            max-height:none;
        }
        .image-container{
            background:var(--yellow);
        }
        p, li, h3{
            font-size: 15px;
        }
        ul {
            padding: 0 0 0 10%;
            margin-top: 0;
        }
        svg{
            opacity:1;
        }
        .bottom{padding:20px;}
    }
}

@media screen and (max-width: 1050px){

    #section_team{
        margin-top:80px;
        .intro{
            display:block;
            > *{
                width: 75%;
                max-width: 75%;
            }
        }  
        .team{
            // flex-wrap:wrap;
            display: block;
            .team-member {
                margin-bottom: var(--margin);
                width: 100%;
                display:flex;
                gap: var(--margin);
                .accolades{
                    display: flex;
                }
                .video-info{
                    height: auto;
                    max-height: inherit;;
                }
                .image-container{
                    flex: 1;
                    height: auto;
                }
                .bottom{
                    flex: 2;
                }
            }
            .team-member {
                p, li {
                    font-size: 15px;
                }
            }
            ul {
                padding: 0 0 0 10%;
                margin-top: 0;
            }
        }
        h4{
            font-size: 1.2rem;
        }
    }

    #section_team .team-member:hover{
        width: inherit;
    }
    #section_team .team-member:hover .image-container{
        background: black;
    }
    #section_team .team-member:hover{
        border: inherit;
        z-index:100;
    }
    #section_team .team-member:hover .accolades{
        // flex-direction: column;
        // gap: 10px;
    }
    #section_team .team-member .bottom{ 
        border: 1px solid var(--grey);
        padding: calc(var(--margin)/2);
    }
    #section_team *{
        transition: 0s !important;
    }
    #section_team .team-member:hover .bottom{
        padding: calc(var(--margin)/2);
    }

}

@media screen and (max-width: 850px){
    #section_team {
        .team{
            .team-member{
                flex-direction: column;
            }
        }
        
    }
    #section_team .team .team-member .image-container{
        height: 200px !important;
        flex:inherit;
    }
}

@media screen and (max-width: 700px){
    #section_team h2 {
        width: 100%;
        max-width: 100%;
        font-size: 2rem;
    }
    #section_team .intro > *{
        width: 100%;
        max-width: 100%;
    }
    #section_team .team-member .accolades{
        flex-direction:column;
        gap: 10px;
    }
    #section_team .team-member .accolades > *{
        width: calc(100% - 20px)
    }
}

#section_research{
    background: black;
    color: white;
    padding-top: 60px;
    padding-bottom: 100px;
    .intro{
        margin-bottom: 80px;
        h2{
            font-weight: 600;
            margin:0;
        }
        // p{
        //     max-width: 600px;
        // }
    }
    .papers{
        display:flex;
        gap: var(--gap);
        > *{
            width: var(--col-3-width);
        }
        h3{
            color: #1B1B1B;
            font-family: var(--serif);
            font-size: 30px;
            font-style: normal;
            font-weight: 300;
            line-height: 116.52%;
            letter-spacing: 0.3px;
            margin:0;
        }
        .paper{
            display: block;
            background: white;
            padding: 20px;
            border-radius: 10px;
            height: 0px;
            padding-bottom: 130%;
            transition: transform 200ms;
            > *{
                max-width: 400px;
            }
        }
        .authors{
            letter-spacing: -0.01em;
            color: #1B1B1B;
            // font-weight: 300;
            // color: #8D8D8D;
        }
        .paper:hover{
            transform:translateY(-20px);
        }
        .paper:hover + p{
            color: var(--yellow);
        }
    }

    @media screen and (max-width: 1050px){
        .papers h3{
            font-size: 22px;
            // font-weight: 400;
        }
    }

    @media screen and (max-width: 860px){
        .papers{
            min-width: 760px;
            padding-right: calc(var(--margin));
            gap: 15px;
            > *{
                width: calc((760px - var(--gap)*2/3));
            }
        }
        .papers:before{
            content: "Scroll →";
            color: #888888;
            font-size: 12px;
            position:absolute;
            top: 0px;
        }
        .papers-container{
            width: calc(100vw - var(--margin)*2);
            padding: 0 var(--margin);
            transform:translateX(calc(var(--margin)*-1));
            overflow-x:scroll;
            overflow-y:visible;
            padding-top: 30px;
            margin-top: -30px;
        }
    }
        
    
}



#news{
    .logo{
        // width: 100px;
        width: 120px;
        height: 60px;
        object-fit: contain;
        // margin-bottom:1em;
    }
    h3{
        font-family: var(--serif);
        font-weight: 300;
        margin:0.5em 0;
    }
    .press-single{
        // border-bottom:1px solid #565656;
        display: block;
        padding: 20px;
        margin-bottom: calc(var(--gap)/2);
        border-radius: 10px;
        // background: #F7F7F1;
        transition: transform 200ms;
        border: 1px solid #ECECE0;
    }
    p{
        margin:0;
    }
    .press-single:hover{
        background: var(--yellow);
        // background: linear-gradient(270deg, #FFEB0A 0%, #FFFF48 100%);
        transform:translateX(20px);
        border-color: transparent;
    }
}
#section_cta{
    background: var(--yellow);
    position:relative;
    margin-top: 300px;
    text-align:center;
    height: 90vh;
    display: flex;
    align-items:center;
    > div{
        margin: auto;
    }
    svg{
        width:auto;
        position:absolute;
        height: 400px;
        left: 50%;
        transform:translateX(-50%) translateY(-100%);
        top:0px;
    }
    h2{
        font-size: 5rem;
        font-weight: 600;
    }
    #putty2{
        bottom: inherit;
        top: 0px;
        height: 100%;
        transform: translateY(-100%);
    }
    @media screen and (max-width: 700px){
        h2{
            font-size: 3rem;
        }
    }
}
.banner {
    position: fixed; /* Stay in place */
    z-index: 201; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.5); /* Black with opacity */
    opacity: 0; /* Hidden by default */
    visibility: hidden; /* Not visible */
    transition: opacity 0.5s ease, visibility 0.5s ease; /* Smooth transition */

    .banner-content {
        background-color: #fff;
        margin: 5vh auto; /* Center it */
        padding: 20px;
        border: 1px solid #888;
        max-width: calc(90vw - 22px);
        max-height: calc(90vh - 22px);
        border-radius: 10px;
        position: relative; /* Needed for close button positioning */
        text-align: center;
        transition: transform 0.6s ease; /* Smooth content transition */
        transform: translateY(-20px); /* Start slightly higher */    
    }
    .xBtn{
        position:absolute;
        top: 5px;
        right: 10px;
        font-weight:normal;
        z-index: 202;
        color: black;
        background: transparent;
        border:0;
        outline:0;
        cursor: pointer;
        font-size: 150%;
        span{
            font-size: 20px;
            line-height: 1;
            transform: translateY(2px);
            display: inline-block;
        }
    }
    /* Banner Image */
    .banner-image {
        max-width: 80vw;
        max-height: calc(80vh - 41px);
        border-radius: 10px;
    }
    .banner-link-container {
        min-height: 40px;
    }
    .banner-link {
        display: inline-block;
    }
}
/* When the banner is visible */
.banner.show {
    opacity: 1; /* Fully visible */
    visibility: visible; /* Show the element */
}